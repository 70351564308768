/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: "Roboto", Verdana, Arial, sans-serif;
}

/*:root {*/
/*    --primary-color: rgb(40, 53, 147);*/
/*    --black-color: #333333;*/
/*    --red-color: #ED1A2D;*/
/*    --border-radius: 2px;*/
/*    --grey: #cccccc;*/
/*    --grey-dark: #999999;*/
/*}*/

/**, *:before, *:after {*/
/*    box-sizing: border-box;*/
/*}*/

.blink {
    animation-name: blink;
    animation-duration: 500ms;
    animation-delay: 250ms;
}

@keyframes blink {
    0% {
        background-color: white;
    }

    50% {
        background-color: rgba(40, 53, 147, 0.1);
    }

    100% {
        background-color: white;
    }
}

@media print {
    img, .hide-on-print, button {
        display: none;
    }

    html, body {
        border: 1px solid white;
        height: 99%;
        page-break-after: avoid;
        page-break-before: avoid;
        background: white;
    }
}
